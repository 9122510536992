.demo-grid {
  min-height: 800px; }

.demo-btn {
  min-height: 20px; }

.demo-ui-color {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin: 8px auto;
  margin: .5rem auto; }

.demo-ui-col {
  width: 20%;
  float: left;
  text-align: center;
  margin-bottom: 32px;
  margin-bottom: 2rem;
  font-size: 12px; }

.demo-sub-title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #212121; }

.demo-logo {
  text-transform: uppercase;
  font-weight: 800;
  color: #000; }
  .demo-logo:hover {
    color: #000; }
  .demo-logo.white {
    color: #fff; }
    .demo-logo.white:hover {
      color: #fff; }

.demo-color-switcher .dropdown-header {
  border: none;
  padding: 0 0 5px 10px;
  font-size: 12px;
  color: #212121; }
  .demo-color-switcher .dropdown-header ~ .dropdown-header {
    margin-top: 16px;
    margin-top: 1rem;
    padding: 16px 0 5px 10px;
    padding: 1rem 0 5px 10px;
    border-top: 1px solid #eee; }

.demo-color-switcher .list-inline {
  margin: 5px 10px 0 10px; }

.demo-color-switcher .active .demo-skin-grid:after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 11px;
  left: 11px; }

.demo-skin-grid {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 2px;
  text-align: center;
  position: relative; }
